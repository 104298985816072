import { GoalStates } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';
import type { ShuttleConnection } from '@sixriver/warehouse-api-graphql-types';

import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';

export function UpdatedAtCell({
	shuttleJob,
}: {
	shuttleJob: ShuttleConnection['edges'][number]['node'];
}) {
	return (
		<IndexTable.Cell>
			{shuttleJob?.state.includes(GoalStates.Running.toLowerCase()) && (
				<>
					<DateTime date={shuttleJob?.lastEventPersistedAt} />
					<RelativeDateTime date={shuttleJob?.lastEventPersistedAt} />
				</>
			)}
			{shuttleJob?.state.includes(GoalStates.Cancelled.toLowerCase()) && (
				<>
					<DateTime date={shuttleJob?.endedAt} />
					<RelativeDateTime date={shuttleJob?.endedAt} />
				</>
			)}
			{shuttleJob?.state.includes(GoalStates.Terminated.toLowerCase()) && (
				<>
					<DateTime date={shuttleJob?.endedAt} />
					<RelativeDateTime date={shuttleJob?.endedAt} />
				</>
			)}
			{shuttleJob?.state.includes(GoalStates.Complete.toLowerCase()) && (
				<>
					<DateTime date={shuttleJob?.endedAt} />
					<RelativeDateTime date={shuttleJob?.endedAt} />
				</>
			)}
			{shuttleJob?.state.includes(GoalStates.Ready.toLowerCase()) && <NoData />}
		</IndexTable.Cell>
	);
}
