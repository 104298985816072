import { useDebouncedValue } from '@shopify/react-hooks';
import { AddMajor } from '@sixriver/lighthouse-icons';
import { Button, Icon, Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import type {
	OrderByDirection,
	StorageLocationConnection,
	StorageLocationOrderByFields,
	StorageLocationType,
	StorageLocationTypeCount,
} from '@sixriver/warehouse-api-graphql-types';
import { useMemo, useState } from 'react';

import { GET_LOCATIONS_QUERY_V2, GET_LOCATION_TYPE_COUNTS_V2 } from './Locations.graphql';
import { LocationsTable } from './LocationsTableV2';
import { useLocationsTableFilters } from './use-locations-table-filters-v2';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { WorkAreaMenu } from '../../components/WorkAreaMenu';
import { boolStringToTriStateBool } from '../../helpers/boolean';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useExperimentalFlags, useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useWorkAreas } from '../../hooks/useWorkAreas';
import * as routes from '../../routes';

export function LocationsV2() {
	const { messages } = useLocalization();
	const experimentalFlags = useExperimentalFlags();
	const isWorkAreasEnabled = useIsWorkAreasEnabled();

	// Work areas
	const { data: workAreas } = useWorkAreas();

	const { isUserAllowed } = useAuth();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters / URL Params
	const { filters } = useLocationsTableFilters();

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Queries
	const [sortByField, sortByDirection] = filters.sort
		? filters.sort.split(' ')
		: [undefined, undefined];

	const searchText = useDebouncedValue(filters.searchText) || '';

	const { workArea } = useFilters(['workArea']);

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const queryVars = {
		after: paginationCursors[0],
		cursor: paginationCursors[0],
		first: getPageSize(),
		fullness: Number(filters.fullness) > -1 ? Number(filters.fullness) : undefined,
		isConflicted: boolStringToTriStateBool(filters.isConflicted),
		isSlotted: boolStringToTriStateBool(filters.isSlotted),
		orderBy: sortByField as StorageLocationOrderByFields,
		orderByDirection: sortByDirection as OrderByDirection,
		searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
		type: filters.view === 'all' ? undefined : (filters.view as StorageLocationType),
	};

	const [{ fetching: fetchingLocations, data: locationsData, error: locationsError }] =
		usePollingQuery<{ locations: StorageLocationConnection }>({
			context: useMemo(
				() => ({
					useWarehouseApi: true,
				}),
				[],
			),
			pollInterval: queryPollInterval,
			query: GET_LOCATIONS_QUERY_V2,
			variables: queryVars,
		});

	const [
		{
			fetching: fetchingLocationTypeCounts,
			data: locationTypeCountsData,
			error: locationsCountError,
		},
	] = usePollingQuery<{ locationTypeCounts: StorageLocationTypeCount[] }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: GET_LOCATION_TYPE_COUNTS_V2,
		variables: queryVars,
	});

	const error = locationsError || locationsCountError;

	const setFilters = useSetFilters();

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			title={messages.locations}
			fullWidth
			primaryAction={
				isWorkAreasEnabled ? (
					<WorkAreaMenu
						workAreas={workAreas}
						selectedIds={selectedWorkAreas || []}
						onChange={(selected: string[]) => {
							setFilters([{ key: 'workArea', value: selected.join(' ') }]);
						}}
					/>
				) : null
			}
		>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						{experimentalFlags.includes('MANAGE_STORAGE_LOCATIONS') ? (
							<Button
								plain
								monochrome
								removeUnderline
								url={routes.addLocation()}
								icon={<Icon source={AddMajor} />}
								disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
							>
								{messages.addLocation}
							</Button>
						) : null}

						<AutoRefresh
							discriminatorData={locationsData}
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<LocationsTable
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						data={locationsData?.locations}
						counts={locationTypeCountsData?.locationTypeCounts}
						loading={fetchingLocations || fetchingLocationTypeCounts}
						workAreas={workAreas}
					/>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
