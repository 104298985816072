import { UserRole } from '@sixriver/react-support';
import { Switch } from 'react-router-dom';

import { Mfa, MfaDataProvider } from '.';
import { MfaSplashPage } from './MfaSplashPage';
import { Mfas } from './Mfas';
import { NewMfa } from './NewMfa';
import * as mfaRoutes from './routes';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { useSiteConfig } from '../../hooks/useConfig';

export function MfaEntryRoute() {
	const siteConfig = useSiteConfig();

	if (!siteConfig.data) {
		return null;
	}

	if (!siteConfig?.data?.globalConfig.bridgeUi?.mfa?.enableBridgePage) {
		return <MfaSplashPage />;
	}

	return (
		<MfaDataProvider>
			<Switch>
				<ProtectedRoute exact path={mfaRoutes.mfas()}>
					<Mfas />
				</ProtectedRoute>
				<ProtectedRoute exact path={mfaRoutes.addMfa()} allowedRoles={[UserRole.Admin]}>
					<NewMfa />
				</ProtectedRoute>
				<ProtectedRoute exact path={mfaRoutes.mfa()} allowedRoles={[UserRole.Admin]}>
					<Mfa />
				</ProtectedRoute>
			</Switch>
		</MfaDataProvider>
	);
}
