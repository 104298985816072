import { ArrowDownMinor, ClipboardMinor } from '@sixriver/lighthouse-icons';
import {
	Button,
	Collapsible,
	CollapsibleCardHeader,
	Icon,
	Modal,
	Text,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import QRCode from 'react-qr-code';

import styles from './ScanQrCodeModal.module.css';
import { useServiceConfig } from '../../../hooks/useConfig';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { useLocalization } from '../../../hooks/useLocalization';

interface ScanQrCodeModalProps {
	open: boolean;
	onDismiss: () => void;
}

export function ApkDownloadModal({ open, onDismiss }: ScanQrCodeModalProps) {
	const serviceConfig = useServiceConfig();
	const { messages } = useLocalization();
	const { copyToClipboard } = useCopyToClipboard();

	const [directDownloadExpanded, setIsDirectDownloadExpanded] = useState(false);
	const [downloadLinkExpanded, setIsDownloadLinkExpanded] = useState(false);
	const [qrCodeExpanded, setIsQrCodeExpanded] = useState(false);

	const mfaVersion = serviceConfig.data?.config?.mfa?.updates?.appVersion ?? 'baseline-install';
	const apkDownloadUrl = `https://storage.googleapis.com/wfe-android-deploy/apk/${mfaVersion}/app-release.apk`;

	return (
		<Modal open={open} onClose={onDismiss} title={messages.newMfa.downloadModal.title}>
			<Modal.Section>
				<Text as="p" variant="bodyMd">
					{messages.newMfa.downloadModal.instructions}
				</Text>
				<br />
				<Text as="p" variant="bodyMd">
					{messages.newMfa.downloadModal.update}
				</Text>
			</Modal.Section>
			<Modal.Section>
				{/** Direct download */}
				<CollapsibleCardHeader
					onToggle={() => setIsDirectDownloadExpanded(!directDownloadExpanded)}
					expanded={directDownloadExpanded}
					title={messages.newMfa.downloadModal.download.title}
					ariaControls="#direct-download"
				>
					<Collapsible open={directDownloadExpanded} id="direct-download">
						<Text as="p" variant="bodyMd">
							{messages.newMfa.downloadModal.download.message}
						</Text>
						<br />
						<Button primary icon={<Icon source={ArrowDownMinor} />} url={apkDownloadUrl}>
							{messages.newMfa.downloadModal.download.button}
						</Button>
					</Collapsible>
				</CollapsibleCardHeader>

				{/** Copy a link */}
				<CollapsibleCardHeader
					onToggle={() => setIsDownloadLinkExpanded(!downloadLinkExpanded)}
					expanded={downloadLinkExpanded}
					title={messages.newMfa.downloadModal.link.title}
					ariaControls="#download-link"
				>
					<Collapsible open={downloadLinkExpanded} id="download-link">
						<Text as="p" variant="bodyMd">
							{messages.newMfa.downloadModal.link.message}
						</Text>
						<br />
						<Text as="p" variant="bodyMd">
							{apkDownloadUrl}
						</Text>
						<Button
							primary
							removeUnderline
							icon={<Icon source={ClipboardMinor} />}
							onClick={() => copyToClipboard(apkDownloadUrl)}
						>
							{messages.newMfa.downloadModal.link.copy}
						</Button>
					</Collapsible>
				</CollapsibleCardHeader>

				{/** QR Code */}
				<CollapsibleCardHeader
					onToggle={() => setIsQrCodeExpanded(!qrCodeExpanded)}
					expanded={qrCodeExpanded}
					title={messages.newMfa.downloadModal.qrCode.title}
					ariaControls="#qr-code"
				>
					<Collapsible open={qrCodeExpanded} id="qr-code">
						<Text as="p" variant="bodyMd">
							{messages.newMfa.downloadModal.qrCode.message}
						</Text>
						<div className={styles.qrCode}>
							<QRCode value={apkDownloadUrl} />
						</div>
					</Collapsible>
				</CollapsibleCardHeader>
			</Modal.Section>
		</Modal>
	);
}
