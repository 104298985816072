import { GoalStates } from '@sixriver/fulfillment-api-schema';
import { Layout, Page, Stack } from '@sixriver/lighthouse-web-community';
import type { ShuttleContainer } from '@sixriver/warehouse-api-graphql-types';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { Details } from './Details';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { SHUTTLE_JOB_QUERY } from './ShuttleJob.graphql';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable/ShuttleJobStatus';

export function ShuttleJob() {
	const { messages } = useLocalization();

	const {
		params: { shuttleJobId },
	} = useRouteMatch<{ shuttleJobId: string }>();

	// query logic
	const [{ data, error, fetching }] = useQuery<{ shuttleJob: ShuttleContainer }>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		query: SHUTTLE_JOB_QUERY,
		variables: {
			id: shuttleJobId,
		},
	});

	const { shuttleJob } = data || {};

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!fetching && !shuttleJob) {
		return <Error heading={messages.shuttleJobNotFound} />;
	}

	return (
		<Page
			title={shuttleJob?.inputs.containerLabels.default || ''}
			titleMetadata={
				<Stack spacing="extraTight">
					<ShuttleJobStatus status={shuttleJob?.state as GoalStates} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<Lines shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
