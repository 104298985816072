import { Page, Layout, Text, Button } from '@sixriver/lighthouse-web-community';

import styles from './MFaSpashPage.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import mfaPickScreen from '../../images/MFa-pick-screen.png';

export function MfaSplashPage(): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Page>
			<Layout>
				<Layout.Section fullWidth>
					<div className={styles.heroLayout}>
						<Layout>
							<Layout.Section oneHalf>
								<Text as="h1" variant="heading2xl">
									{messages.mfaSplashPage.hero.title}
								</Text>
								<div className={styles.messages}>
									<Text as="p" variant="bodyLg">
										{messages.mfaSplashPage.hero.messageOne}
									</Text>
									<Text as="p" variant="bodyLg">
										{messages.mfaSplashPage.hero.messageTwo}
									</Text>
								</div>
								<Button primary url="mailto:customersuccess-6rs@ocado.com">
									{messages.mfaSplashPage.hero.cta}
								</Button>
								<Text as="p" variant="bodySm">
									customersuccess-6rs@ocado.com
								</Text>
								<div className={styles.learnMore}>
									<Button plain url="https://6river.com/mobile-fulfillment/">
										{messages.mfaSplashPage.hero.learnMore}
									</Button>
								</div>
							</Layout.Section>
							<Layout.Section oneHalf>
								<img src={mfaPickScreen} alt={messages.mfaSplashPage.hero.imageAlt} />
							</Layout.Section>
						</Layout>
					</div>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
