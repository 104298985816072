import { useFetch } from '@hyper-fetch/react';
import { SiteConfig } from '@sixriver/config-schema';

import { GlobalErrorType, getSiteConfig } from '../../api';

export function useSiteConfig(): {
	data: SiteConfig | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getSiteConfig);
	return { data, error, fetching: loading };
}

export function useIsWarehouseApiEnabled(): boolean {
	const { data: siteConfig } = useSiteConfig();
	return siteConfig?.globalConfig.bridgeUi?.warehouseApiEnabled !== false;
}

export function useIsSupportEnabled(): boolean {
	const { data: siteConfig } = useSiteConfig();
	return siteConfig?.globalConfig.bridgeUi?.supportEnabled === true;
}

export function useSsid(): string | undefined {
	const { data: siteConfig } = useSiteConfig();
	return siteConfig?.globalConfig.wifiConfig?.ssid;
}

export function useIsMfaPageEnabled(): boolean {
	const { data: siteConfig } = useSiteConfig();
	return siteConfig?.globalConfig.bridgeUi?.mfa?.enableBridgePage === true;
}
