import { useDebouncedValue } from '@shopify/react-hooks';
import {
	ChoiceList,
	FilterInterface,
	Layout,
	Page,
	Stack,
} from '@sixriver/lighthouse-web-community';
import {
	ShuttleConnection,
	OrderByDirection,
	ShuttleOrderByFields,
} from '@sixriver/warehouse-api-graphql-types';
import { useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { SHUTTLE_JOBS_QUERY, COUNT_SHUTTLE, GET_EXCEPTION_REASONS } from './ShuttleJobs.graphql';
import { ShuttleJobsTable } from './ShuttleJobsTable';
import { ShuttleJobsViews, ShuttleJobsViewsViewStateMap } from './ShuttleJobsViews';
import { AutoRefresh } from '../../components/AutoRefresh';
import { Error } from '../../components/Error';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { Toteboard } from '../../components/Toteboard';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState } from '../../hooks/useQueryState';

const EXCEPTION_REASONS_KEY = 'exceptionReasons';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export interface ShuttleOrderBy {
	direction: OrderByDirection;
	field: ShuttleOrderByFields;
}

export function ShuttleJobs() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const defaultView = ShuttleJobsViews.All;

	// Pagination
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<ShuttleOrderBy[]>([
		{ direction: OrderByDirection.Desc, field: ShuttleOrderByFields.ContainerId },
	]);

	// filtering logic
	const {
		query,
		view = defaultView,
		[EXCEPTION_REASONS_KEY]: exceptionParam,
	} = useFilters([EXCEPTION_REASONS_KEY]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedExceptions = exceptionParam ? exceptionParam.split(',') : undefined;

	const statuses = ShuttleJobsViewsViewStateMap[view as ShuttleJobsViews];

	const [{ fetching: shuttleJobsFetching, data: shuttleJobsData, error: shuttleJobsError }] =
		usePollingQuery<{ shuttleJobs: ShuttleConnection }>({
			context: useMemo(
				() => ({
					useWarehouseApi: true,
				}),
				[],
			),
			pollInterval: queryPollInterval,
			query: SHUTTLE_JOBS_QUERY,
			variables: {
				after: paginationCursors[0],
				cursor: paginationCursors[0],
				exceptionReasons: selectedExceptions,
				first: getPageSize(),
				hasExceptions: exceptionParam && exceptionParam.length > 0 ? true : false,
				orderBy: selectedSort.length > 0 ? selectedSort : undefined,
				searchText: actualSearchText,
				statuses,
			},
		});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		countShuttles: {
			unassigned: number;
			inProgress: number;
			interrupted: number;
			completed: number;
			cancelled: number;
		};
	}>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		pollInterval: queryPollInterval,
		query: COUNT_SHUTTLE,
	});

	const [
		{ fetching: exceptionCountsFetching, data: exceptionCountsData, error: exceptionCountsError },
	] = useQuery<{
		getExceptions: {
			reason: string;
			count: number;
		}[];
	}>({
		context: useMemo(
			() => ({
				useWarehouseApi: true,
			}),
			[],
		),
		query: GET_EXCEPTION_REASONS,
	});

	const {
		unassigned = 0,
		inProgress = 0,
		interrupted = 0,
		completed = 0,
		cancelled = 0,
	} = countsData?.countShuttles || {};

	const totalCount = unassigned + inProgress + completed + cancelled ?? 0;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	// big card counts
	const toteboardItems = [
		{
			label: messages.unassigned,
			primaryValue: <span>{unassigned}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: <span>{inProgress}</span>,
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{interrupted}</span>,
		},
		{
			label: messages.canceled,
			primaryValue: <span>{cancelled}</span>,
		},
	];

	const fetching = shuttleJobsFetching || countsFetching || exceptionCountsFetching;
	const error = shuttleJobsError || countsError || exceptionCountsError;

	const exceptionReasons = exceptionCountsData?.getExceptions?.map((exc) => exc.reason);

	const filters: FilterInterface[] = [];
	if (exceptionReasons?.length) {
		filters.push({
			filter: (
				<ChoiceList
					title={messages.shuttle.exceptions}
					titleHidden
					choices={exceptionReasons.map((er) => ({ label: er, value: er }))}
					selected={selectedExceptions || []}
					onChange={(selected) => {
						setFilters([{ key: EXCEPTION_REASONS_KEY, value: selected.join(',') }]);
					}}
					allowMultiple
				/>
			),
			key: EXCEPTION_REASONS_KEY,
			label: messages.shuttle.exceptions,
			shortcut: true,
		});
	}

	const appliedFilters = [
		...(selectedExceptions
			? [
					{
						key: EXCEPTION_REASONS_KEY,
						label: selectedExceptions.map((reason) => reason).join(', '),
						onRemove: () => {
							setFilters([{ key: EXCEPTION_REASONS_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Views
	const views = [
		{
			id: ShuttleJobsViews.All,
			label: messages.all,
		},
		{
			id: ShuttleJobsViews.Unassigned,
			label: messages.unassigned,
			metaLabel: unassigned,
		},
		{
			id: ShuttleJobsViews.InProgress,
			label: messages.inProgress,
			metaLabel: inProgress,
		},
		{
			id: ShuttleJobsViews.Interrupted,
			label: messages.interrupted,
			metaLabel: interrupted,
		},
		{
			id: ShuttleJobsViews.Completed,
			label: messages.completed,
			metaLabel: completed,
		},
		{
			id: ShuttleJobsViews.Cancelled,
			label: messages.canceled,
			metaLabel: cancelled,
		},
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.shuttleJobs}>
			<Layout>
				<Layout.Section>
					<Toteboard items={toteboardItems} />
					<Stack distribution="trailing">
						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={{}}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<ShuttleJobsTable
						data={shuttleJobsData?.shuttleJobs?.edges}
						fetching={fetching}
						pageInfo={shuttleJobsData?.shuttleJobs?.pageInfo}
						query={query}
						selectedView={view}
						setFilters={setFilters}
						totalPages={totalPages}
						views={views}
						selectedSort={selectedSort}
						onSortChange={setSelectedSort}
						filters={filters}
						appliedFilters={appliedFilters}
						setPaginationCursors={setPaginationCursors}
						paginationCursors={paginationCursors}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
