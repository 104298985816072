import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import * as routes from '../../../routes';

interface Props {
	id: string;
	containerId?: string | null;
}

export function LicensePlateCell({ id, containerId }: Props) {
	return (
		<IndexTable.Cell>
			<Link url={routes.shuttleJob(id)} key="shuttle" removeUnderline>
				{containerId}
			</Link>
		</IndexTable.Cell>
	);
}
