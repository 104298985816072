import { MapApi, useMap } from '@sixriver/map-element';
import { useEffect } from 'react';

/**
 * The <Map> element wraps a <MapOptions> component that acts as a conduit
 * for the imperative MapApi. Touch this code at your own risk.
 */
export let mapApi: MapApi;

export function MapOptions({ fullscreen }: { fullscreen: boolean }) {
	mapApi = useMap();
	mapApi.setEditOptions('chuck', fullscreen ? [] : ['selectable']);
	mapApi.setEditOptions('aprilTag', fullscreen ? [] : ['selectable']);
	mapApi.setEditOptions('workflowPoint', fullscreen ? [] : ['selectable']);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const id = params.get('chuck');

		if (id) {
			mapApi.selectFeatures(id);
		}
	}, []);

	return null;
}
