import { useFetch } from '@hyper-fetch/react';

import {
	GlobalErrorType,
	AvailableMfp,
	getAvailableMfps,
	getAvailableMfp,
	MfpConfigExtended,
	DeviceType,
	DeviceState,
} from '../api';

export function useAvailableMfps(): {
	data: AvailableMfp[];
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getAvailableMfps);
	return { data: data ?? [], error, fetching: loading };
}

export function useAvailableMfp(id: string): {
	data: AvailableMfp | null;
	error: GlobalErrorType | null | undefined;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getAvailableMfp.setParams({ id }));
	return { data, error, fetching: loading };
}

export function getDeviceType(config: MfpConfigExtended): DeviceType {
	return determineDeviceType(config.config?.deviceClass);
}

export function determineDeviceType(deviceClass: string | undefined): DeviceType {
	switch (deviceClass) {
		case 'packoutTerminal':
			return DeviceType.PackoutTerminal;
		case 'sortationKiosk':
			return DeviceType.SortationKiosk;
		case 'terminalOnWheels':
			return DeviceType.TerminalOnWheels;
		case 'handheld':
			return DeviceType.Handheld;
		case 'chuck':
			return DeviceType.Chuck;
		case undefined:
			return DeviceType.Chuck;
		default:
			return DeviceType.Unknown;
	}
}

export function getDeviceState(device: AvailableMfp): DeviceState {
	switch (device.state) {
		case 'ALLOCATED':
		case 'ALLOCATED_REMOTE':
			return DeviceState.InUse;
		case 'AVAILABLE':
		case 'AVAILABLE_REMOTE':
			return DeviceState.NotInUse;
		case 'OFFLINE':
			return DeviceState.Disconnected;
	}
}
