import { Card, Select } from '@sixriver/lighthouse-web-community';

import SettingsSection from './SettingsSection';
import SettingsSectionConfig from './SettingsSectionConfig';

class InternationalizationSection extends SettingsSection {
	private options: { label: string; value: string }[] = [
		{ label: 'English', value: 'en-US' },
		{ label: 'Spanish', value: 'es-MX' },
		{ label: 'Dutch', value: 'nl' },
		{ label: 'Czech', value: 'cs' },
		{ label: 'Polish', value: 'pl' },
	];

	private fallbackLocale: SettingsSectionConfig;
	private setDirty: (isDirty: boolean) => void;
	private messages: any;

	constructor(
		fallbackLocale: SettingsSectionConfig,
		setDirty: (isDirty: boolean) => void,
		messages: any,
	) {
		super();
		this.fallbackLocale = fallbackLocale;
		this.setDirty = setDirty;
		this.messages = messages;
	}

	handleLocaleChange = (value: string) => {
		this.fallbackLocale.setSelected(value);
		this.setDirty(true);
		this.fallbackLocale.setError('');
	};

	validate = (): boolean => {
		const isValid = this.options.some((option) => option.value === this.fallbackLocale.selected);
		if (!isValid) {
			this.fallbackLocale.setError(this.messages.fallbackLocaleError);
			return false;
		}

		return true;
	};

	visit = (config: any, changes: string[]): void => {
		config.site.globalConfig.internationalization ??= {};

		if (this.fallbackLocale.original !== this.fallbackLocale.selected) {
			config.site.globalConfig.internationalization.fallbackLocale = this.fallbackLocale.selected;

			changes.push('site.globalConfig.internationalization.fallbackLocale');
		}
	};

	discard = () => {
		this.fallbackLocale.setSelected(this.fallbackLocale.original);
		this.fallbackLocale.setError('');
	};

	render = (): JSX.Element => {
		return (
			<div id="internationalization">
				<Card title={this.messages.internationalizationSettingsSection} sectioned>
					<Select
						label={this.messages.fallbackLocaleLabel}
						options={this.options}
						onChange={this.handleLocaleChange}
						value={this.fallbackLocale.selected}
						error={this.fallbackLocale.error === '' ? false : this.fallbackLocale.error}
						helpText={this.messages.fallbackLocaleHelpText}
					/>
				</Card>
			</div>
		);
	};
}

export default InternationalizationSection;
