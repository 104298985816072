import { GoalStates } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { ShuttleJobStatus } from './ShuttleJobStatus';

export function ProgressCell({ status }: { status?: GoalStates }) {
	return (
		<IndexTable.Cell>
			<ShuttleJobStatus status={status} />
		</IndexTable.Cell>
	);
}
