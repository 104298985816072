import { Page, Layout, ContextualSaveBar, Banner, Toast } from '@sixriver/lighthouse-web-community';
import { useCallback, useState, useMemo } from 'react';

import { useConfigServiceClient } from './ConfigServiceContext';
import InternationalizationSection from './Internationalization';
import SettingsSection from './SettingsSection';
import { useSiteConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

export function Settings() {
	const { messages } = useLocalization();
	const pageTitle = messages.siteSettings;

	const { data: siteConfig } = useSiteConfig();

	const fallbackLocale = siteConfig?.globalConfig.internationalization?.fallbackLocale || 'en-US';
	const [selectedLocale, setSelectedLocale] = useState<string>(fallbackLocale);
	const [localeError, setLocaleError] = useState<string>('');

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [generalError, setGeneralError] = useState<string>('');

	const sections: SettingsSection[] = useMemo(() => {
		return [
			new InternationalizationSection(
				{
					error: localeError,
					original: fallbackLocale,
					selected: selectedLocale,
					setError: setLocaleError,
					setSelected: setSelectedLocale,
				},
				setIsDirty,
				messages,
			),
		];
	}, [fallbackLocale, selectedLocale, localeError, messages]);

	const configServiceClient = useConfigServiceClient();

	const [toastActive, setToastActive] = useState(false);
	const toggleToastActive = useCallback(() => setToastActive((active) => !active), []);

	const handleSave = useCallback(async () => {
		const isValid = sections.every((section) => section.validate());
		if (!isValid) {
			return;
		}

		setIsSaving(true);
		const config = {
			site: { globalConfig: {} },
		};
		const changes: string[] = [];

		sections.forEach((section) => {
			section.visit(config, changes);
		});

		const finalPayload = {
			changes,
			config,
		};

		try {
			await configServiceClient.saveConfig(JSON.stringify(finalPayload));

			setIsDirty(false);
			toggleToastActive();
		} catch (error) {
			setGeneralError('An error occurred. Please try again.');
		} finally {
			setIsSaving(false);
		}
	}, [sections, configServiceClient, toggleToastActive]);

	const handleDiscard = useCallback(() => {
		sections.forEach((section) => section.discard());

		setIsDirty(false);
		setGeneralError('');
	}, [sections]);

	return (
		<Page title={pageTitle}>
			{generalError && (
				<div style={{ marginBottom: '20px' }}>
					<Banner title="Error" status="critical">
						<p>{generalError}</p>
					</Banner>
				</div>
			)}
			<Layout>
				{sections.map((section, index) => (
					<Layout.Section key={index}>{section.render()}</Layout.Section>
				))}
			</Layout>
			{isDirty && (
				<ContextualSaveBar
					message="Unsaved changes"
					saveAction={{
						disabled: isSaving,
						loading: isSaving,
						onAction: handleSave,
					}}
					discardAction={{
						onAction: handleDiscard,
					}}
				/>
			)}
			{toastActive ? (
				<Toast content={messages.configSavedToast} onDismiss={toggleToastActive} />
			) : null}
		</Page>
	);
}
