import { Card, Button } from '@sixriver/lighthouse-web-community';
import type { ShuttleContainer } from '@sixriver/warehouse-api-graphql-types';

import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { getMfpUrl } from '../../helpers/mfp';
import { useLocalization } from '../../hooks/useLocalization';

export function Details({ shuttleJob }: { shuttleJob?: ShuttleContainer }) {
	const { messages } = useLocalization();

	const user = shuttleJob?.currentActor?.user;
	const device = shuttleJob?.currentActor?.device;

	const deviceInfo: { deviceId: string; deviceType: string } | undefined =
		device?.type === 'Mfp'
			? {
					deviceId: device.id,
					deviceType: 'mfp',
			  }
			: undefined;

	const userIdsString = user?.id;

	const uniqueChuck = deviceInfo?.deviceId;

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					primary={[
						{
							content: userIdsString || <NoData />,
							label: messages.assignedBy,
						},
						// {
						// 	label: messages.sourceLocation,
						// 	content: <NoData />,
						// },
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={shuttleJob?.createdAt} />
									<RelativeDateTime date={shuttleJob?.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: <DateTime date={shuttleJob?.lastEventPersistedAt} />,
							label: messages.updatedAt,
						},
						{
							content: <DateTime date={shuttleJob?.endedAt} />,
							label: messages.completedAt,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							content: uniqueChuck || <NoData />,
							label: messages.chuck,
						},
					]}
					secondary={[
						{
							content: shuttleJob?.outputs.takenOffAt || <NoData />,
							label: messages.takeoff,
						},
					]}
				/>
				<br />
				{/* TODO: account for finding multiple devices */}
				<Button
					plain
					url={getMfpUrl(uniqueChuck && uniqueChuck[0])}
					disabled={uniqueChuck?.length === 0}
				>
					{messages.findThisChuck}
				</Button>
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							content: shuttleJob?.id ? <CopyButton text={shuttleJob.id} /> : null,
							label: messages.uniqueJobId,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
