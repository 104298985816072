import { createContext, useContext, ReactNode } from 'react';

import { ConfigServiceClient } from './ConfigServiceClient';
import { useSiteConfig } from '../../hooks/useConfig/useSiteConfig';

export const ConfigServiceContext = createContext<ConfigServiceClient | null>(null);

export const ConfigServiceProvider = ({ children }: { children: ReactNode }) => {
	const { data: siteConfig } = useSiteConfig();
	const client = new ConfigServiceClient(siteConfig?.id);

	return <ConfigServiceContext.Provider value={client}>{children}</ConfigServiceContext.Provider>;
};

export const useConfigServiceClient = () => {
	const context = useContext(ConfigServiceContext);
	if (!context) {
		throw new Error('useConfigServiceClient must be used within a ConfigServiceProvider');
	}

	return context;
};
