import { Product, ProductKind, QueryProductArgs } from '@sixriver/fulfillment-api-schema';
import { CircleTickOutlineMinor, CircleDisableMinor } from '@sixriver/lighthouse-icons';
import {
	Page,
	Layout,
	Card,
	Banner,
	Thumbnail,
	Stack,
	TextContainer,
	Icon,
} from '@sixriver/lighthouse-web-community';
import { useRouteMatch } from 'react-router-dom';

import { LEGACY_PRODUCT_QUERY } from './Product.graphql';
import { AutoRefresh } from '../../components/AutoRefresh';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import { transformImageUrl } from '../../utils';

export function LegacyProductDetails() {
	const { messages, translate, formatLength, formatWeight } = useLocalization();

	const {
		params: { productId },
	} = useRouteMatch<{ productId: string }>();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching, data, error }] = usePollingQuery<{ product: Product }, QueryProductArgs>({
		pollInterval: queryPollInterval,
		query: LEGACY_PRODUCT_QUERY,
		variables: {
			id: productId,
		},
	});

	const product = data?.product;
	const imgPath = product?.image;
	const imgDesc = product?.description || '';
	const isContainer = product?.kind === ProductKind.Packaging;

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			title={product?.customerIdentifier}
			secondaryActions={[
				{
					content: messages.edit,
					disabled: isContainer,
					url: routes.editProduct(product?.id),
				},
			]}
		>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={data}
					/>
				</Layout.Section>
				<Layout.Section>
					<Card sectioned title={messages.productDetails}>
						<CardDetails
							loading={fetching}
							primary={[
								{
									content: product?.customerIdentifier,
									label: messages.item,
								},
								{
									content: product?.name,
									label: messages.name,
								},
								{
									content: product?.description,
									label: messages.description,
								},
								{
									content: product?.scanValues.join(', ') || <NoData />,
									label: messages.identifiers,
								},
								{
									content: isContainer
										? translate(messages.productTypes.packaging)
										: translate(messages.productTypes.product),
									label: messages.productType,
								},
								{
									content: imgPath ? (
										<Thumbnail source={transformImageUrl(imgPath)} alt={imgDesc} size="large" />
									) : (
										<NoData />
									),
									label: messages.image,
								},
							]}
						/>
						{isContainer ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewContainer,
										url: routes.container(product?.id),
									}}
									status="info"
								>
									<p>{messages.productIsContainer}</p>
								</Banner>
							</>
						) : null}
					</Card>
				</Layout.Section>
				<Layout.Section oneHalf>
					<Card sectioned title={messages.dimensions}>
						<Stack distribution="fillEvenly">
							<TextContainer>{messages.length}</TextContainer>
							<TextContainer>{isContainer ? 'N/A' : formatLength(product?.length)}</TextContainer>
						</Stack>
						<Stack distribution="fillEvenly">
							<TextContainer>{messages.width}</TextContainer>
							<TextContainer>{isContainer ? 'N/A' : formatLength(product?.width)}</TextContainer>
						</Stack>
						<Stack distribution="fillEvenly">
							<TextContainer>{messages.height}</TextContainer>
							<TextContainer>{isContainer ? 'N/A' : formatLength(product?.height)}</TextContainer>
						</Stack>
						<Stack distribution="fillEvenly">
							<TextContainer>{messages.weight}</TextContainer>
							<TextContainer>{isContainer ? 'N/A' : formatWeight(product?.weight)}</TextContainer>
						</Stack>
					</Card>
				</Layout.Section>
				<Layout.Section oneHalf>
					<Card sectioned title={messages.skuAttributes}>
						{product?.attributes?.lot ? (
							<Stack>
								<Icon source={CircleTickOutlineMinor} />
								<p>{messages.isLotTracked}</p>
							</Stack>
						) : (
							<Stack>
								<Icon source={CircleDisableMinor} />
								<p>{messages.isNotLotTracked}</p>
							</Stack>
						)}
						{product?.attributes?.expiresAt ? (
							<Stack>
								<Icon source={CircleTickOutlineMinor} />
								<p>{messages.isExpirationTracked}</p>
							</Stack>
						) : (
							<Stack>
								<Icon source={CircleDisableMinor} />
								<p>{messages.isNotExpirationTracked}</p>
							</Stack>
						)}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
