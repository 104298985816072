export class ConfigServiceClient {
	private siteID: string | undefined;

	constructor(siteID: string | undefined) {
		this.siteID = siteID;
	}

	async saveConfig(payload: string) {
		const url = `/cfs/config-service/sites/${this.siteID}`;

		const response = await fetch(url, {
			body: payload,
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'PUT',
		});

		if (!response.ok) {
			throw new Error('Failed to save configuration. Please try again.');
		}
	}
}
