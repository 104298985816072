import { ProductInput } from '@sixriver/fulfillment-api-schema';
import {
	Card,
	ContextualSaveBar,
	Form,
	FormLayout,
	TextField,
	Layout,
	Stack,
} from '@sixriver/lighthouse-web-community';

import { FormFeedback } from '../../components/FormFeedback';
import { ImageUpload } from '../../components/ImageUpload/ImageUpload';
import { useExperimentalFlags } from '../../hooks/useConfig';
import { FormProps, useForm } from '../../hooks/useForm';
import { useLocalization } from '../../hooks/useLocalization';

export function ProductForm({ mode, data, error, onSubmit }: FormProps<ProductInput>): JSX.Element {
	const { messages } = useLocalization();
	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<ProductInput>(
		data,
		error,
	);

	const experimentalFlags = useExperimentalFlags();

	return (
		<Form onSubmit={() => void onSubmit(input)} noValidate>
			<FormFeedback feedback={feedback} />
			<Layout>
				<Layout.AnnotatedSection title={messages.productDetails}>
					<Card sectioned>
						<Stack vertical>
							<TextField
								autoComplete="off"
								name="customerIdentifier"
								label={messages.item}
								value={input.customerIdentifier}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(customerIdentifier) => editForm({ customerIdentifier })}
								error={validations.customerIdentifier}
							/>
							<TextField
								autoComplete="off"
								name="name"
								label={messages.name}
								value={input.name}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(name) => editForm({ name })}
								error={validations.name}
							/>
							<TextField
								autoComplete="off"
								name="description"
								label={messages.description}
								value={input.description}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(description) => editForm({ description })}
								error={validations.description}
							/>
							<ImageUpload
								imgUrl={input.image || undefined}
								resourceName={messages.productImage}
								onImgUrl={(image) => {
									editForm({ image: image?.trim() ? image : null });
								}}
							/>
						</Stack>
					</Card>
				</Layout.AnnotatedSection>
				<Layout.AnnotatedSection title={messages.productAttributes}>
					<Card sectioned>
						<Stack vertical>
							<FormLayout>
								<FormLayout.Group condensed>
									<TextField
										autoComplete="off"
										name="length"
										label={messages.length}
										value={(input.length || 0).toString()}
										requiredIndicator
										disabled={mode === 'edit' && !experimentalFlags.includes('EDIT_PRODUCT')}
										type="number"
										inputMode="numeric"
										suffix="mm"
										min="1"
										onChange={(length) => editForm({ length: parseFloat(length) })}
										error={validations.length}
									/>
									<TextField
										autoComplete="off"
										name="width"
										label={messages.width}
										value={(input.width || 0).toString()}
										requiredIndicator
										disabled={mode === 'edit' && !experimentalFlags.includes('EDIT_PRODUCT')}
										type="number"
										inputMode="numeric"
										suffix="mm"
										min="1"
										onChange={(width) => editForm({ width: parseFloat(width) })}
										error={validations.width}
									/>
									<TextField
										autoComplete="off"
										name="height"
										label={messages.height}
										value={(input.height || 0).toString()}
										requiredIndicator
										disabled={mode === 'edit' && !experimentalFlags.includes('EDIT_PRODUCT')}
										type="number"
										inputMode="numeric"
										suffix="mm"
										min="1"
										onChange={(height) => editForm({ height: parseFloat(height) })}
										error={validations.height}
									/>
								</FormLayout.Group>
							</FormLayout>
							<TextField
								autoComplete="off"
								name="weight"
								label={messages.weight}
								value={input.weight.toString()}
								requiredIndicator
								disabled={mode === 'edit' && !experimentalFlags.includes('EDIT_PRODUCT')}
								type="number"
								inputMode="numeric"
								suffix={'g'}
								min="1"
								onChange={(weight) => editForm({ weight: parseFloat(weight) })}
								error={validations.weight}
							/>
						</Stack>
					</Card>
				</Layout.AnnotatedSection>
			</Layout>
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: () => void onSubmit(input),
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}
		</Form>
	);
}
