import {
	OrderV2Edge,
	PageInfo,
	PickStrategy,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import {
	EmptySearchResult,
	IndexTable,
	IndexTableProps,
	useIndexResourceState,
} from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useCallback, useState } from 'react';

import { DateReceivedCell } from './DateReceivedCell';
import { ExpectedShipDateCell } from './ExpectedShipDateCell';
import { ExternalIdCell } from './ExternalIdCell';
import { PickStrategyCell } from './PickStrategyCell';
import { QuantityCell } from './QuantityCell';
import { SortWallCell } from './SortWallCell';
import { StatusCell } from './StatusCell';
import { StoreCell } from './StoreCell';
import { TagCell } from './TagCell';
import { WorkOrderTypeCell } from './WorkOrderTypeCell';
import { WorkAreaCell } from '../../../components/WorkAreaCell';
import { useLocalization } from '../../../hooks/useLocalization';
import { BulkOrderCancelConfirmationModal } from '../BulkOrderCancelConfirmationModal';

enum SelectionType {
	All = 'all',
	Page = 'page',
	Multi = 'multi',
	Single = 'single',
}

export interface OrdersTableProps {
	loading: boolean;
	data?: OrderV2Edge[];
	showPickStrategyColumn?: boolean;
	showWorkOrderTypeColumn?: boolean;
	showStoreColumn?: boolean;
	showWorkAreaColumn?: boolean;
	showOrderTagColumn?: boolean;
	pageInfo?: PageInfo;
	showSortWallColumn?: boolean;
	onBulkCancel: (selectedIds: string[]) => Promise<void>;
}

export function OrdersTable({
	loading,
	data = [],
	showPickStrategyColumn = false,
	showWorkOrderTypeColumn = false,
	showStoreColumn = false,
	showWorkAreaColumn = false,
	showOrderTagColumn = false,
	pageInfo,
	showSortWallColumn = false,
	onBulkCancel,
}: OrdersTableProps) {
	const [isBulkCancelConfirmModalOpen, setIsBulkCancelConfirmModalOpen] = useState<boolean>(false);

	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const { selectedResources: selectedOrderIds, handleSelectionChange } = useIndexResourceState(
		data,
		{ resourceIDResolver: (edge) => edge.node.id },
	);

	const resourceName = {
		plural: messages.orders.toLowerCase(),
		singular: messages.order.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noOrdersFound} withIllustration />;
	const headings: IndexTableProps['headings'] = [
		{ title: messages.order },
		{ title: messages.receivedAt },
		{ title: messages.carrierCutoff },
		{ title: messages.quantity },
		...(showSortWallColumn ? [{ title: messages.sortWall }] : []),
		...(showWorkOrderTypeColumn ? [{ title: messages.workOrderType }] : []),
		{ title: messages.orderStatus },
		...(showPickStrategyColumn ? [{ title: messages.pickStrategy }] : []),
		...(showStoreColumn ? [{ title: messages.store }] : []),
		...(showWorkAreaColumn ? [{ title: messages.workArea }] : []),
		...(showOrderTagColumn ? [{ title: messages.tag }] : []),
	] as IndexTableProps['headings'];

	const rows = data.map(({ node }, index) => {
		const {
			id,
			externalId,
			pickStrategy,
			createdAt,
			expectedShipDate,
			isLate,
			totalLines,
			totalLineQuantities,
			totalLineCustomers,
			workOrderTypes,
			destinationNode,
			lines,
		} = node;

		return (
			<IndexTable.Row id={id} key={id} position={index} selected={selectedOrderIds.includes(id)}>
				<ExternalIdCell
					orderId={id}
					externalId={externalId}
					destinationNode={destinationNode as string}
				/>
				<DateReceivedCell createdAt={createdAt} />
				<ExpectedShipDateCell expectedShipDate={expectedShipDate} isLate={isLate ?? false} />
				<QuantityCell totalLineQuantities={totalLineQuantities} totalLines={totalLines} />
				{showSortWallColumn ? <SortWallCell order={node} /> : undefined}
				{showWorkOrderTypeColumn ? (
					<WorkOrderTypeCell
						key={`${id}-workOrderTypes`}
						types={workOrderTypes as WorkOrderType[]}
					></WorkOrderTypeCell>
				) : undefined}
				<StatusCell order={node} />
				{showPickStrategyColumn ? (
					<PickStrategyCell pickStrategy={pickStrategy ?? undefined} />
				) : undefined}
				{showStoreColumn ? <StoreCell totalLineCustomers={totalLineCustomers} /> : undefined}
				{showWorkAreaColumn ? <WorkAreaCell lines={(lines as any).edges} /> : undefined}
				{showOrderTagColumn ? <TagCell order={node} /> : undefined}
			</IndexTable.Row>
		);
	});

	const noCancel = data.some(
		({ node }) =>
			selectedOrderIds.includes(node?.id) &&
			[PickStrategy.Singles, PickStrategy.Sortation, PickStrategy.Bulk].includes(
				node?.pickStrategy as PickStrategy,
			),
	);

	const promotedBulkActions: IndexTableProps['promotedBulkActions'] = [
		{
			content: messages.cancelOrders,
			disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || noCancel,
			onAction: () => {
				setIsBulkCancelConfirmModalOpen(true);
			},
		},
	];

	const onModalClose = () => {
		handleSelectionChange(SelectionType.All, false);
		setIsBulkCancelConfirmModalOpen(false);
	};

	const handleBulkCancel = useCallback(async () => {
		setIsBulkCancelConfirmModalOpen(false);

		// only send specific order ids if All is NOT selected
		await onBulkCancel(selectedOrderIds);

		handleSelectionChange(SelectionType.All, false);
	}, [selectedOrderIds, onBulkCancel, handleSelectionChange]);

	return (
		<>
			<IndexTable
				emptyState={emptyStateMarkup}
				resourceName={resourceName}
				headings={headings}
				hasMoreItems={pageInfo?.hasPreviousPage || pageInfo?.hasNextPage}
				itemCount={data.length}
				loading={loading}
				selectedItemsCount={selectedOrderIds.length}
				onSelectionChange={handleSelectionChange}
				promotedBulkActions={promotedBulkActions}
			>
				{rows}
			</IndexTable>
			<BulkOrderCancelConfirmationModal
				isOpen={isBulkCancelConfirmModalOpen}
				onClose={onModalClose}
				submitBulkCancelRequest={handleBulkCancel}
				selectedOrderIds={selectedOrderIds}
			/>
		</>
	);
}
