import { useDebouncedValue } from '@shopify/react-hooks';
import { JobFlowRuleInput } from '@sixriver/fulfillment-api-schema';
import { DeleteMinor, PlusMinor } from '@sixriver/lighthouse-icons';
import {
	Autocomplete,
	Button,
	Card,
	ContextualSaveBar,
	Form,
	Modal,
	Select,
	Combobox,
	SelectOption,
	Stack,
	TextField,
	Listbox,
	TextStyle,
	Spinner,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import {
	JFRConditionAttribute,
	JFRConditionOperator,
	JobFlowRuleCriteria,
	JFRCondition,
	JFRDimension,
	JFRJoin,
} from './JobFlowRuleCriteria';
import {
	useAddressOptions,
	useAisleOptions,
	useContainerOptions,
	useEquipmentName,
	useFilterLabel,
	useWorkAreaOptions,
} from './JobFlowRuleHelpers';
import styles from './JobFlowRules.module.css';
import { Error } from '../../components/Error';
import { FormFeedback } from '../../components/FormFeedback';
import { getJobFlowRuleFilters } from '../../helpers/job-flow-rule-filters';
import { useEquipmentTypes, useIsWorkAreasEnabled, useServiceConfig } from '../../hooks/useConfig';
import { FormProps, useForm } from '../../hooks/useForm';
import { useLocalization } from '../../hooks/useLocalization';

export default function JobFlowRuleForm({ data, error, onSubmit }: FormProps<JobFlowRuleInput>) {
	const { messages } = useLocalization();
	const [modalVisible, setModalVisible] = useState(false);
	const getEquipmentName = useEquipmentName();
	const getFilterLabel = useFilterLabel();
	const configuredEquipmentTypes = useEquipmentTypes();
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	if (!configuredEquipmentTypes.includes('chuck')) {
		configuredEquipmentTypes.unshift('chuck');
	}

	const equipmentOptions = configuredEquipmentTypes.map((type) => {
		return {
			label: getEquipmentName(type),
			value: type,
		};
	});

	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<JobFlowRuleInput>(
		data,
		error,
	);

	const { data: serviceConfigData, fetching, error: serviceConfigError } = useServiceConfig();
	const filters = getJobFlowRuleFilters(serviceConfigData);

	// Some inputted values are copied here for the sole purpose of debouncing them and feeding them to queries
	const [searchText, setSearchText] = useState({
		'containerType.externalId': undefined,
		'sourceLoc.address': undefined,
	});

	const assistedOptions: { [k: string]: SelectOption[] } = {
		'containerType.externalId': useContainerOptions(
			useDebouncedValue(searchText['containerType.externalId']),
		),

		// These values are loaded on demand (as the user types)
		'sourceLoc.address': useAddressOptions(useDebouncedValue(searchText['sourceLoc.address'])),

		'sourceLoc.externalAisleId': useAisleOptions(),

		// These values are loaded up front
		'sourceLoc.mapChunkId': useWorkAreaOptions(),
	};

	// These are user-defined values for custom filters
	filters.forEach((f) => {
		if (f.values.length) {
			assistedOptions['data.' + f.key] = f.values;
		}
	});

	if (fetching) {
		return <Spinner />;
	}
	if (serviceConfigError) {
		return <Error graphQLError={serviceConfigError} />;
	}
	if (filters.length === 0) {
		return <Error message={messages.jfrFiltersMissing} />;
	}

	// Pass the user input into a data structure that can drive this form
	const criteria = new JobFlowRuleCriteria(input, filters);

	// These are attributes that apply only to 'line' rules
	const lineOpts: Array<{ title: string; keys: (JFRConditionAttribute | string)[] }> = [
		{
			keys: [
				'sourceLoc.address',
				'sourceLoc.externalAisleId',
				'sourceLoc.requiredReach',
				// "work areas" won't show up for most sites
				...(isWorkAreaEnabled ? ['sourceLoc.mapChunkId' as JFRConditionAttribute] : []),
			],
			title: messages.location,
		},
		{
			keys: [
				'productType.length',
				'productType.width',
				'productType.height',
				'productType.weight',
				'quantity',
			],
			title: messages.product,
		},
	];

	// These are attributes that apply only to 'job' rules
	const jobOpts: Array<{ title: string; keys: (JFRConditionAttribute | string)[] }> = [
		{
			keys: ['weight', 'containerType.externalId'],
			title: 'Job',
		},
	];

	// These are "custom" attributes that apply to both 'line' and 'job' rules
	if (filters.length) {
		lineOpts.push({
			keys: filters.map((f) => 'data.' + f.key),
			title: messages.custom,
		});

		jobOpts.push({
			keys: filters.map((f) => 'data.' + f.key),
			title: messages.custom,
		});
	}

	function editJoin(join: JFRJoin) {
		criteria.setJoin(join);

		editForm({ rule: criteria.toObject() });
	}

	function addCondition() {
		const jobCondition: JFRCondition = {
			attribute: 'weight',
			operator: 'eq',
			value: 0,
		};

		const lineCondition: JFRCondition = {
			attribute: 'sourceLoc.address',
			operator: 'eq',
			value: '',
		};

		criteria.addCondition(criteria.getDimension() === 'job' ? jobCondition : lineCondition);

		editForm({ rule: criteria.toObject() });
	}

	function deleteCondition(idx: number) {
		criteria.deleteCondition(idx);

		editForm({ rule: criteria.toObject() });
	}

	function editDimension(dimension: JFRDimension) {
		criteria.setDimension(dimension);

		addCondition();
	}

	function editAttribute(idx: number, attribute: JFRConditionAttribute) {
		criteria.setConditionAttribute(idx, attribute);

		editForm({ rule: criteria.toObject() });
	}

	function editOperator(idx: number, operator: JFRConditionOperator) {
		criteria.setConditionOperator(idx, operator);

		editForm({ rule: criteria.toObject() });
	}

	function editValue(idx: number, value: string | number) {
		criteria.setConditionValue(idx, value);

		editForm({ rule: criteria.toObject() });
	}

	function saveForm() {
		const conditions = criteria.getConditions();

		// We are doing a bit of validation before submission to the API. This is an anti-pattern!
		if (conditions.some((c) => c.value === '' || c.value === null)) {
			setModalVisible(true);
			return;
		}

		if (input.ruleSubType.length === 0) {
			setModalVisible(true);
			return;
		}

		onSubmit(input);
	}

	function updateEquipmentSelection(selected: string) {
		if (input.ruleSubType.includes(selected)) {
			editForm({ ruleSubType: input.ruleSubType.filter((option) => option !== selected) });
		} else {
			editForm({ ruleSubType: [...input.ruleSubType, selected] });
		}
	}

	const equipmentOptionsMarkup =
		equipmentOptions.length > 0
			? equipmentOptions.map((option) => {
					const { label, value } = option;
					return (
						<Listbox.Option
							key={`${value}`}
							value={value}
							selected={input.ruleSubType.includes(value)}
							accessibilityLabel={label}
						>
							{label}
						</Listbox.Option>
					);
			  })
			: null;

	return (
		<Form onSubmit={saveForm} noValidate>
			<FormFeedback feedback={feedback} />
			<Card sectioned title={messages.ruleProperties}>
				<Stack distribution="fillEvenly">
					<TextField
						autoComplete="off"
						name="ruleDescription"
						label={messages.name}
						value={input.ruleDescription}
						requiredIndicator
						maxLength={1024}
						onChange={(ruleDescription) => editForm({ ruleDescription })}
						error={validations.ruleDescription}
					/>
					<Select
						name="ruleType"
						label={messages.ruleType}
						options={[
							{ label: messages.line, value: 'line' },
							{ label: messages.order, value: 'job' },
						]}
						helpText={messages.jobFlowRuleHints[criteria.getDimension()]}
						value={criteria.getDimension()}
						onChange={editDimension}
					/>
				</Stack>
			</Card>
			<Card sectioned title={messages.ruleConditions}>
				<table className={styles.formTable}>
					<tbody>
						<tr>
							<td>{messages.jobFlowRuleExpressions.use}</td>
							<td>
								<Stack vertical spacing="tight">
									<Combobox
										allowMultiple
										activator={
											<Combobox.TextField
												autoComplete="off"
												readOnly
												label={messages.equipment}
												labelHidden
												value={input.ruleSubType.map(getEquipmentName).join(', ')}
											/>
										}
									>
										{equipmentOptionsMarkup ? (
											<Listbox onSelect={updateEquipmentSelection}>
												{equipmentOptionsMarkup}
											</Listbox>
										) : null}
									</Combobox>
									<TextStyle variation="subdued">{messages.jobFlowRuleHints.equipment}</TextStyle>
								</Stack>
							</td>
						</tr>
						{criteria.getConditions().map((condition, i) => {
							const constraints = criteria.getConditionConstraints(i);
							const join = criteria.getJoin();

							return (
								<tr key={i}>
									<td>
										{i === 0 ? (
											messages.jobFlowRuleExpressions.when
										) : i === 1 ? (
											<Select
												name="join"
												label=""
												value={join}
												options={[
													{ label: messages.jobFlowRuleExpressions.and, value: 'and' },
													{ label: messages.jobFlowRuleExpressions.or, value: 'or' },
												]}
												onChange={editJoin}
											/>
										) : (
											messages.jobFlowRuleExpressions[join]
										)}
									</td>
									<td>
										<Select
											name="attribute"
											label={messages.attribute}
											labelHidden
											value={condition.attribute}
											options={(criteria.getDimension() === 'line' ? lineOpts : jobOpts).map(
												(opts) => {
													return {
														options: opts.keys.map((key) => ({
															label: key.startsWith('data.')
																? getFilterLabel(key)
																: messages.jobFlowRuleAttributes[key as JFRConditionAttribute],
															value: key,
														})),
														title: opts.title,
													};
												},
											)}
											onChange={editAttribute.bind(null, i)}
										/>
									</td>
									<td>
										<Select
											name="operator"
											label=""
											labelHidden
											value={condition.operator}
											options={constraints.operators.map((operator) => {
												return {
													label: messages.jobFlowRuleExpressions[operator],
													value: operator,
												};
											})}
											onChange={editOperator.bind(null, i)}
										/>
									</td>
									<td>
										{constraints.fieldType === 'text' ? (
											// This is plain text field, but its input type may toggle between "text" and "number"
											<TextField
												autoComplete="off"
												name="value"
												label={messages.jobFlowRuleAttributes[condition.attribute]}
												labelHidden
												value={condition.value.toString()}
												type={constraints.dataType === 'string' ? 'text' : 'number'}
												inputMode={constraints.dataType === 'string' ? 'text' : 'numeric'}
												suffix={constraints.suffix}
												min="0"
												requiredIndicator
												onChange={(value) => {
													editValue(i, constraints.dataType === 'string' ? value : +value);
												}}
											/>
										) : constraints.fieldType === 'select' ? (
											// This is a select field with enumerated values
											<Select
												name="value"
												label={messages.jobFlowRuleAttributes[condition.attribute]}
												labelHidden
												placeholder={messages.select}
												value={condition.value.toString()}
												options={assistedOptions[condition.attribute]}
												onChange={editValue.bind(null, i)}
											/>
										) : (
											// This is a text field with type-ahead capabilities
											<Autocomplete
												options={(assistedOptions[condition.attribute] as any).filter(
													(opt: any) => {
														return opt.value
															.toLowerCase()
															.includes(condition.value.toString().toLowerCase());
													},
												)}
												selected={[condition.value.toString()]}
												onSelect={(value) => editValue(i, value[0])}
												preferredPosition="below"
												textField={
													<Autocomplete.TextField
														name="value"
														label={messages.jobFlowRuleAttributes[condition.attribute]}
														labelHidden
														value={condition.value.toString()}
														onChange={(value) => {
															// Register the inputted value only if 3+ chars. This is a limitation on the API side
															setSearchText({
																...searchText,
																[condition.attribute]: value.length >= 3 ? value : undefined,
															});
															editValue(i, value);
														}}
														autoComplete="off"
													/>
												}
											/>
										)}
									</td>
									<td>
										<Stack spacing="tight" wrap={false}>
											{criteria.getConditions().length > 1 ? (
												// We can delete any condition except for the first one
												<Button
													onClick={deleteCondition.bind(null, i)}
													icon={DeleteMinor}
													accessibilityLabel={messages.deleteCondition}
												/>
											) : null}
											{i === criteria.getConditions().length - 1 ? (
												<Button
													onClick={addCondition}
													icon={PlusMinor}
													accessibilityLabel={messages.addCondition}
												/>
											) : null}
										</Stack>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</Card>

			<br />

			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.jobFlowRuleCannotSave}
				primaryAction={{
					content: messages.close,
					onAction: () => setModalVisible(false),
				}}
			>
				<Modal.Section>{messages.jobFlowRuleIncomplete}</Modal.Section>
			</Modal>

			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: saveForm,
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}
		</Form>
	);
}
