import { IndexTable, TextStyle } from '@sixriver/lighthouse-web-community';
import type { ShuttleConnection } from '@sixriver/warehouse-api-graphql-types';

import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';

export function ExceptionsCell({
	lines,
}: {
	lines: ShuttleConnection['edges'][number]['node']['lines'];
}) {
	const { messages, translate } = useLocalization();

	const exceptions = lines?.flatMap((line) => {
		return line?.outputs?.transfers?.flatMap((t: any) => t.exceptions);
	});

	return (
		<IndexTable.Cell>
			{' '}
			{exceptions?.length > 0 ? (
				<TextStyle>
					{translate(messages.countExceptions, {
						count: exceptions?.length,
					})}
				</TextStyle>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
