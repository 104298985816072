import { LineEdgeV2 } from '@sixriver/fulfillment-api-schema';
import { EmptySearchResult, IndexTable, IndexTableProps } from '@sixriver/lighthouse-web-community';

import { AssociatesCell } from './AssociateCell';
import { ExceptionBadgeCell } from './ExceptionBadgeCell';
import { PackingUnitsCell } from './PackingUnitsCell';
import { PickingUnitsCell } from './PickingUnitsCell';
import { ProductIdCell } from './ProductIdCell';
import { ProductNameCell } from './ProductNameCell';
import { ProgressCell } from './Progress';
import { ReasonCell } from './ReasonCell';
import { SourceAddressCell } from './SourceAddressCell';
import { useLocalization } from '../../../hooks/useLocalization';
import { WorkAreaCell } from '../../WorkAreaCell';

export enum LinesTableColumn {
	id = 'id',
	name = 'name',
	pickingUnits = 'pickingUnits',
	packingUnits = 'packingUnits',
	location = 'location',
	associate = 'associate',
	exceptions = 'exceptions',
	progress = 'progress',
	reason = 'reason',
	workArea = 'workArea',
}
interface Props {
	columns: (LinesTableColumn | null)[];
	loading: boolean;
	data?: LineEdgeV2[];
}

export function LinesTable({ columns, loading, data = [] }: Props) {
	const { messages } = useLocalization();

	const resourceName = {
		plural: messages.lines.toLowerCase(),
		singular: messages.line.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noLinesFound} withIllustration />;

	const headings = columns
		.map((column) => {
			switch (column) {
				case LinesTableColumn.id:
					return { title: messages.item };
				case LinesTableColumn.name:
					return { title: messages.name };
				case LinesTableColumn.pickingUnits:
					return { title: messages.unitsPicked };
				case LinesTableColumn.packingUnits:
					return { title: messages.units };
				case LinesTableColumn.location:
					return { title: messages.location };
				case LinesTableColumn.associate:
					return { title: messages.associate };
				case LinesTableColumn.exceptions:
					return { title: messages.exceptions };
				case LinesTableColumn.progress:
					return { title: messages.progress };
				case LinesTableColumn.reason:
					return { title: messages.reason };
				case LinesTableColumn.workArea:
					return { title: messages.workArea };
				case null:
				default:
					return null;
			}
		})
		.filter((column) => !!column);

	const rows = data.map(({ node: line }, index) => {
		return (
			<IndexTable.Row id={line.id} key={line.id} position={index}>
				{columns
					.map((column) => {
						switch (column) {
							case LinesTableColumn.id:
								return <ProductIdCell line={line} key="id" />;
							case LinesTableColumn.name:
								return <ProductNameCell line={line} key="name" />;
							case LinesTableColumn.pickingUnits:
								return <PickingUnitsCell line={line} key="picking-units" />;
							case LinesTableColumn.packingUnits:
								return <PackingUnitsCell line={line} key="packing-units" />;
							case LinesTableColumn.location:
								return <SourceAddressCell line={line} key="location" />;
							case LinesTableColumn.associate:
								return <AssociatesCell line={line} key="associate" />;
							case LinesTableColumn.exceptions:
								return <ExceptionBadgeCell line={line} key="exceptions" />;
							case LinesTableColumn.progress:
								return <ProgressCell line={line} key="progress" />;
							case LinesTableColumn.reason:
								return <ReasonCell line={line} key="reason" />;
							case LinesTableColumn.workArea:
								return <WorkAreaCell lines={[data[index]]} key="work-area" />;
							case null:
							default:
								return null;
						}
					})
					.filter((column) => !!column)}
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings as IndexTableProps['headings']}
			hasMoreItems={false}
			itemCount={rows.length}
			loading={loading}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
